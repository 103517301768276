import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslate } from '../../../features/polyglot';
import { useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';

import useServices from 'Hooks/useServices';
import useCities from 'Hooks/useCities';

import { GridCol, media, Row } from 'Components/Layout';
import { Col, Icon } from 'ui-55';
import { Heading } from 'Components/Text';
import Tabs from 'Components/Tabs';
import Button from 'Components/Button';
import Slider from 'Components/Slider';
import CityFilter from 'Components/CityFilter';

import { ServiceFilterContainer, ServiceFilterTextContainer, ServiceTabsContainer } from './styles';

const ServicesTab = () => {
  const { services, loading } = useServices();
  const { city } = useCities();
  const cityServices = useMemo(
    () =>
      services.filter(svc => {
        const serviceCities = svc?.attributes?.active_cities ?? [];
        return serviceCities.indexOf(Number(city?.id)) !== -1;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [city?.id, services]
  );

  const history = useHistory();
  const t = useTranslate('home.services');

  const servicesSlides = useMemo(
    () =>
      cityServices.map(d => ({
        category: d?.attributes?.parent_category,
        text: d?.attributes?.name,
        img: d?.attributes?.cover,
        content_key: d?.attributes?.content_key
      })),
    [cityServices]
  );

  const [slides, setSlides] = useState(servicesSlides);

  const handleSlidesSelection = useCallback(
    event => {
      if (event === 'Todos' && slides.length !== servicesSlides.length) {
        setSlides(servicesSlides);
      } else {
        const items = servicesSlides.filter(
          e => e.category === event.toLowerCase()
        );
        setSlides(items);
      }
    },
    [servicesSlides, slides]
  );

  useEffect(() => {
    if (JSON.stringify(slides) !== JSON.stringify(servicesSlides)) {
      setSlides(servicesSlides);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, servicesSlides]);

  const handleSliderNavigation = event => {
    const filteredItem = services.find(e => e.attributes.name === event.text);
    history.push(`services/${filteredItem.id}?city=${city?.attributes?.name}`);
  };
  const tabs = useMemo(() => {
    const uniqCategories = new Set(
      servicesSlides.map(serv => capitalize(serv?.category))
    );

    return [...uniqCategories].length > 1
      ? [{ name: 'Todos', label: 'todos' }, ...uniqCategories.map(cat => ({ name: cat, label: cat.toLowerCase() }))]
      : [...uniqCategories.map(cat => ({ name: cat, label: cat.toLowerCase() }))];
  }, [servicesSlides]);
  return (
    <GridCol>
      <ServiceFilterContainer>
        <Row>
          {!media.mobile && (
            <>
              <Heading size={2}>{t('header')}</Heading>
              <ServiceFilterTextContainer align='baseline'>
                <Icon name='map-pin' />
                <Heading size={2}>{t('country')}</Heading>
              </ServiceFilterTextContainer>
            </>
          )}
          {media.mobile && (
            <Col align='center'>
              <Heading size={2}>{t('header')}</Heading>
              <CityFilter isRed isBottomBorder />
            </Col>
          )}
        </Row>
        <ServiceTabsContainer>
          <Tabs
            action={event => handleSlidesSelection(event)}
            justify='center'
            tabs={tabs}
            initialTabIndex={0}
          />
        </ServiceTabsContainer>
      </ServiceFilterContainer>
      {cityServices.length && !loading ? (
        <>
          <Slider action={item => handleSliderNavigation(item)} list={slides} />
          <Row justify='center'>
            <Button
              isFullWidth
              btnType='primary'
              text={t('button')}
              action={() =>
                history.push('/services?city=' + city?.attributes?.name)
              }
            />
          </Row>
        </>
      ) : (
        <Heading align='center' size={3}>
          {t('noServices')}
        </Heading>
      )}
    </GridCol>
  );
};

export default ServicesTab;
